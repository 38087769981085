import React, { useState, useEffect } from 'react';
// import { Box, Button, Grid, Paper, Typography, Container } from '@mui/material';
import { Box, Button, Grid, Paper, Typography, Container } from '@mui/material';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';


import { makeStyles } from '@mui/styles';
// import MoneyIcon from '@mui/icons-material/Money';
// import MoneyIcon from '@mui/icons-material/AccountBalance';
// import MoneyIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import MoneyIcon from '@mui/icons-material/AccountBalanceWalletSharp';
// import { create } from '@mui/material/styles/createTransitions';

// import ImageWithFallback from './components/ImageWithFallback'; // Импортируем созданный компонент

import { useMediaQuery } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  balanceBox: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '10px',
    backgroundColor: '#f5f5f5',
    borderRadius: '4px',
    marginBottom: '20px',
  },
  balanceText: {
    fontWeight: 'bold',
    marginLeft: '5px',
  },
  tokenControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '8px',
  },
  tokenBalance: {
    margin: '0 10px',
  },
  tableContainer: {
    maxWidth: '1050px',
    width: '100%',
    margin: '0 auto',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: '16px',
  },
  rowContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  rowHeader: {
    flexGrow: 1,
  },
  rowFooter: {
    alignSelf: 'flex-end',
  },
  enlargedImage: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '90%',
    maxHeight: '90%',
    zIndex: 1200, // Make sure it's above other content
  },
}));

const TokenTable = () => {
  const classes = useStyles();

  const [totalBalance, setTotalBalance] = useState(0);
  const [balances, setBalances] = useState([]);
  const [userId, setUserId] = useState('');
  const [step, setStep] = useState(1);
  const [head, setHead] = useState('');
  const [isStop, setIsStop] = useState(false);
  const [Createdate, setCreatedate] = useState('');

  const [enlargedImage, setEnlargedImage] = useState(null);
  
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleImageClick = (image) => {
    if (isSmallScreen) {
      setEnlargedImage(image);
    }
  };

  const handleCloseImage = () => {
    setEnlargedImage(null);
  };

  const [userFormData, setUserFormData] = useState({
    gender: '',
    sphera: '',
    educationLevel: '',
    mlevel: '',
    birthYear: '',
  });

  const [isFormFilled, setIsFormFilled] = useState(false);
  useEffect(() => {
    // Проверяем, заполнены ли все три анкетных поля
    const isFilled = Object.values(userFormData).every(value => value !== '');
    setIsFormFilled(isFilled);
}, [userFormData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://xclimg.bot-n.ru/get_data_to_fill');
        const data = await response.json();
        setBalances(data.balances);
        setTotalBalance(data.total_balance);
        setUserId(data.user_id); // Сохранение user_id
        setStep(data.step); // Сохранение шага
        setHead(data.head);
        setCreatedate(data.createdate);
        // console.log(Createdate);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleFormChange = (e) => {
    console.log("Changed input:", e.target.name, e.target.value);
    
    setUserFormData({
      ...userFormData,
      [e.target.name]: e.target.value
    });
  };
  

  const handleIncrease = (id) => {
    if (totalBalance > 0) {
      setBalances(balances.map(item =>
        item.id === id ? { ...item, balance: item.balance + 1 } : item
      ));
      setTotalBalance(totalBalance - 1);
    }
  };

  const handleDecrease = (id) => {
    const item = balances.find(item => item.id === id);
    if (item.balance > 0) {
      setBalances(balances.map(item =>
        item.id === id ? { ...item, balance: item.balance - 1 } : item
      ));
      setTotalBalance(totalBalance + 1);
    }
  };

  const handleSend = async () => {
    const distributionInfo = {
      step,
      user_id: userId,
      total_balance: totalBalance,
      balances: balances.map(item => ({ id: item.id, name: item.name, balance: item.balance })),
      userData: userFormData, // Включение данных пользователя в объект
      createdate: Createdate
    };
    console.log(distributionInfo);
    try {
      const response = await fetch('https://xclimg.bot-n.ru/save_data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(distributionInfo)
      });

      const result = await response.json();

      if (result.message === 'IS STOP') {
        setIsStop(true);
      } else {
        setBalances(result.balances);
        setTotalBalance(result.total_balance);
        setStep(result.step); // Обновление шага
        setHead(result.head);
      }
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };

  const handleImageError = (row) => {
    // Функция, которая изменяет расширение файла .jpg на .png
    const changeToPng = (name) => {
      return name.replace(/\.jpg$/, '.png');
    };

    // Проверяем, что имя файла заканчивается на .jpg
    if (row.name.endsWith('.jpg')) {
      // Создаем новый массив с обновленным именем файла
      const updatedBalances = balances.map(item => {
        if (item.id === row.id) {
          return { ...item, name: changeToPng(item.name) };
        }
        return item;
      });

      // Обновляем состояние с новым массивом balances
      setBalances(updatedBalances);
    }
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const isIpad = useMediaQuery('(min-width: 667px) and (max-width: 1024px)');

  return (
    <Container className={classes.mainContainer}>
      <Box className={classes.tableContainer}>

      {isFormFilled && (
        <Box className={classes.balanceBox} display="flex" alignItems="center">
       {isMobile || isIpad ? (
        <Typography sx={{ fontSize: "1.4rem" }}>Баланс токенов:</Typography>
      ) : (
        <Typography>Распределите {totalBalance} токенов между указанными изображениями.</Typography>
      )}
          <Box ml={3} display="flex" alignItems="center">
            <Typography className={classes.balanceText} sx={{ fontSize: "1.8rem" }}>
            {!isMobile && !isIpad && "баланс токенов:"} {totalBalance}<MoneyIcon />
            </Typography>
          </Box>
        </Box>
      )}

        {/* Рендер формы для получения данных пользователя перед основным содержимым */}
        {step === 1 && !isFormFilled && (
          // Рендер формы для получения данных пользователя
            <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6">Анкетные данные пользователя</Typography>
            <FormControl style={{ marginBottom: '10px' }}>
                <InputLabel id="gender-label" sx={{ bgcolor: "#fff"}}>Пол</InputLabel>
                <Select
                    labelId="gender-label"
                    name="gender"
                    value={userFormData.gender}
                    onChange={handleFormChange}
                    >
                    <MenuItem value="male">М</MenuItem>
                    <MenuItem value="female">Ж</MenuItem>
                </Select>
            </FormControl>
            {/* <FormControl style={{ marginBottom: '10px' }}>
                <InputLabel id="education-level-label" sx={{ bgcolor: "#fff"}}>Уровень образования</InputLabel>
                <Select
                    labelId="education-level-label"
                    id="education-level-select"
                    value={userFormData.educationLevel}
                    onChange={handleFormChange}
                    name="educationLevel"
                >
                    <MenuItem value="BSc3">Бакалавриат (3 курс)</MenuItem>
                    <MenuItem value="BSc4">Бакалавриат (4 курс)</MenuItem>
                    <MenuItem value="MSc1">Магистратура (1 курс)</MenuItem>
                    <MenuItem value="MSc2">Магистратура (2 курс)</MenuItem>
                </Select>
            </FormControl> */}
            <FormControl style={{ marginBottom: '10px' }}>
                <InputLabel id="birthYear-label" sx={{ bgcolor: "#fff"}}>Год рождения</InputLabel>
                <Select
                    labelId="birthYear-label"
                    name="birthYear"
                    value={userFormData.birthYear}
                    onChange={handleFormChange}
                >
                    {Array.from({length: 86}, (_, i) => 2010 - i).map(year => (
                        <MenuItem key={year} value={year}>{year}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl style={{ marginBottom: '10px' }}>
                <InputLabel id="sp-level-label" sx={{ bgcolor: "#fff"}}>Cфера деятельности * </InputLabel>
                <Select
                    labelId="sp-level-label"
                    id="sp-level-select"
                    value={userFormData.sphera}
                    onChange={handleFormChange}
                    name="sphera"
                >
                    <MenuItem value="s1">Без акцентов на специфику</MenuItem>
                    <MenuItem value="s2">Безопасность и правоохранение</MenuItem>
                    <MenuItem value="s3">Бизнес и предпринимательство</MenuItem>
                    <MenuItem value="s4">Информационные технологии</MenuItem>
                    <MenuItem value="s5">Медицина и здравоохранение</MenuItem>
                    <MenuItem value="s6">Менеджмент и управление</MenuItem>
                    <MenuItem value="s7">Педагогика и воспитание</MenuItem>
                    <MenuItem value="s8">Промышленность и индустрия</MenuItem>
                    <MenuItem value="s9">Связь и коммуникации</MenuItem>
                    <MenuItem value="s10">Строительство и транспорт</MenuItem>
                    <MenuItem value="s11">Торговля и сервисы</MenuItem>
                    <MenuItem value="s12">Финансовые инструменты</MenuItem>
                    <MenuItem value="s13">Сельское хозяйство</MenuItem>
                </Select>
            </FormControl>
            <FormControl style={{ marginBottom: '10px' }}>
                <InputLabel id="education-level-label" sx={{ bgcolor: "#fff"}}>Уровень образования * </InputLabel>
                <Select
                    labelId="education-level-label"
                    id="education-level-select"
                    value={userFormData.educationLevel}
                    onChange={handleFormChange}
                    name="educationLevel"
                >
                    <MenuItem value="BSb0">Без образования</MenuItem>
                    <MenuItem value="BSl1">Высшее образование — специалитет, магистратура</MenuItem>
                    <MenuItem value="BSl2">Высшее образование — бакалавриат</MenuItem>
                    <MenuItem value="BSl3">Два и более высших образования</MenuItem>
                    <MenuItem value="BSb1">Среднее общее образование</MenuItem>
                    <MenuItem value="BSh1">Ученая степень</MenuItem>
                    <MenuItem value="BSb2">Среднее профессиональное образование</MenuItem>
                </Select>
            </FormControl>
            
            <FormControl style={{ marginBottom: '10px' }}>
                <InputLabel id="m-level-label" sx={{ bgcolor: "#fff"}}>Материальное положение</InputLabel>
                <Select
                    labelId="m-level-label"
                    id="m-level-select"
                    value={userFormData.mlevel}
                    onChange={handleFormChange}
                    name="mlevel"
                >
                    <MenuItem value="l3">Достаток. Не испытываю затруднений, помогаю материально другим</MenuItem>
                    <MenuItem value="l2">Бедность. Денег не хватает на продукты питания, лекарства, одежду</MenuItem>
                    <MenuItem value="l1">Нуждаюсь. Денег хватает лишь на основные продукты питания и одежду</MenuItem>
                    <MenuItem value="l0">Нищета. Живу в крайней нужде</MenuItem>
                    <MenuItem value="l4">Стабильное. Особых материальных затруднений нет, но не все покупки по карману</MenuItem>
                    <MenuItem value="l5">Устойчивое. Нет никаких материальных затруднений</MenuItem>
                </Select>
            </FormControl>
            * Просьба указать информацию о главе семьи.
            </Paper>
        )}

        {isFormFilled && !isStop && step === 1 && balances.length > 3 &&(
        <Paper elevation={3} className={classes.paper}>
            <Typography variant="body1" style={{ marginBottom: '10px' }}>
            Пожалуйста, распределите 10 токенов между указанными изображениями таким образом, чтобы у изображения наиболее подходящего, по Вашему мнению, к предложенным терминам было наибольшее количество токенов, а у менее подходящего изображения было наименьшее количество токенов.
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '10px' }}>
                {/* Содержимое второго абзаца */}
                *Пример:
                    Термин 
                    Изображение 3 – 1 токен 
                    Изображение 6 – 5 токенов
                    Изображение 10 – 4 токена

            </Typography>
            <Typography variant="body1" style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                Также Вы вправе отдать 10 токенов одному изображению.
            </Typography>
        </Paper>
        )}

        {isFormFilled && !isStop && step === 1 && balances.length < 4 &&(
        <Paper elevation={3} className={classes.paper}>
            <Typography variant="body1" style={{ marginBottom: '10px' }}>
            Пожалуйста, распределите 10 токенов между тремя вариантами определения предложенных ниже терминов таким образом, чтобы 
у наиболее подходящего, по Вашему мнению, варианта определения термина было наибольшее количество токенов, а у менее подходящего варианта определения термина было наименьшее количество токенов.

            </Typography>
            <Typography variant="body1" style={{ marginBottom: '10px' }}>
                {/* Содержимое второго абзаца */}
                *Пример:
                    Термин 
                    Определение 1 – 1 токен 
                    Определение 2 – 5 токенов
                    Определение 3 – 4 токена

            </Typography>
            <Typography variant="body1" style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                Также Вы вправе отдать 10 токенов одному определению.
            </Typography>
        </Paper>
        )}

        {isFormFilled && !isStop &&(
        <Box mt={2}>
          <Typography variant="h5" align="center">{head}</Typography>
        </Box>
        )}
        
        {isFormFilled && !isStop &&(
        <Grid container spacing={2}>
          {balances.map((row) => (
            <Grid item xs={12} sm={balances.length > 3 ? 3 : 4} key={row.id}>
              {/* <Grid item xs={6} sm={4} md={3} lg={3} key={row.id}> */}
              <Paper elevation={3} className={classes.paper} style={{ padding: '4px' }}>
                <div className={classes.rowContent} style={{ padding: '4px' }}>
                {row.name.endsWith('.jpg') || row.name.endsWith('.png') || row.name.endsWith('.jpeg') ? (
                //  <img src={`/images/step${step}/${row.name}`} alt={row.name} style={{ width: '100%' }} />
                <img
                src={`/images/step${step}/${row.name}`}
                alt={row.name}
                style={{ width: '100%' }}
                onClick={() => handleImageClick(row.name)}
                onError={() => handleImageError(row)}
              />
                ) : (
                  <Typography variant="h7" className={classes.rowHeader}>{row.name}</Typography>
                )}

                {isSmallScreen && enlargedImage === row.name && (
                  <div>
                    <img
                      src={`/images/step${step}/${row.name}`}
                      alt={row.name}
                      className={classes.enlargedImage}
                      onClick={handleCloseImage}
                    />
                    <div className={classes.rowFooter}>
                      <div className={classes.tokenControls}>
                        <Button variant="contained" color="primary" onClick={() => handleIncrease(row.id)}>+</Button>
                        <span className={classes.tokenBalance}>{row.balance}</span>
                        <Button variant="contained" color="secondary" onClick={() => handleDecrease(row.id)}>-</Button>
                      </div>
                    </div>
                  </div>
                )}
                  <div className={classes.rowFooter}>
                    <div className={classes.tokenControls}>
                      <Button variant="contained" color="primary" onClick={() => handleIncrease(row.id)}>+</Button>
                      <span className={classes.tokenBalance}>{row.balance}</span>
                      <Button variant="contained" color="secondary" onClick={() => handleDecrease(row.id)}>-</Button>
                    </div>
                  </div>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
        )}
      {enlargedImage && (
        <div className={classes.enlargedImage} onClick={handleCloseImage}>
          {/* <img
            src={`/images/step${step}/${enlargedImage}`}
            alt={enlargedImage}
            style={{ width: '100%' }}
          /> */}
        </div>
      )}
        {/* {totalBalance === 0 && !isStop && (
          <Box mt={6}>
            <Button variant="contained" color="primary" onClick={handleSend}>Отправить</Button>
          </Box>
        )} */}
        {totalBalance === 0 && !isStop ? (
            <Box mt={6} mb={4}>
                <Button variant="contained" color="primary" onClick={handleSend}>Отправить</Button>
            </Box>
        ) : (
            <Box mt={6} mb={4}>
                <Button variant="contained" color="primary" disabled>Отправить</Button>
            </Box>
        )}
      </Box>
      {isStop && (
        <Box mt={4}>
          <Typography variant="h6" align="center">Опрос окончен.</Typography>
        </Box>
      )}
       <Box mt={4}>
        <Typography variant="body1" align="right" style={{ marginBottom: '8px', fontWeight: 'lighter', color: 'grey'}}>
                {userId}  | step: {step}
        </Typography>
        </Box>
    </Container>

  );
}

export default TokenTable;
